import { defineStore } from 'pinia';

export const useJivoStore = defineStore('jivo', () => {
  const bottomOffset = ref(16);
  const isHidden = ref(false);
  const openChat = () => {
    if (window.jivo_api && !isHidden.value) {
      window.jivo_api.open();
    }
  };
  return {
    bottomOffset,
    isHidden,
    openChat,
  };
});
